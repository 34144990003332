import React from "react";
import Baski3D from "../../Assets/printervector.svg";
import YapayZeka from "../../Assets/Artificial.svg";
import Tarama from "../../Assets/3dscanner.png";
import { firebaseApp } from "../../Config/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import LoadingScreen from "../LoadingScreen";
const auth = getAuth(firebaseApp);
function BodyCards() {
  let navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className=" grid lg:grid-cols-3 gap-5 lg:gap-5 md:justify-center md:items-center ">
      <div className="h-full py-5 sm:max-w-sm md:max-w-md lg:max-w-lg bg-white border border-gray-200 rounded-lg shadow hover:shadow-slate-500">
        <span className="text-center font-extralight px-4 text-2xl text-blue-500">
          Prototipleme
        </span>
        <div>
          <img
            className="rounded-t-lg mx-auto"
            src={Baski3D}
            alt=""
            width={120}
          />
        </div>
        <div className="p-5 mx-auto text-center">
          <p className="mb-3 font-normal text-gray-400 ">
            FDM, SLA ve SLS metodları ile prototip üretim hizmeti
          </p>
          <div className="flex justify-center pt-10 sm:pt-10 md:pt-10">
            <button
              onClick={() =>
                user != null
                  ? navigate("/prototipleme")
                  : navigate("/giris", { state: { topage: "/prototipleme" } })
              }
              className=" bg-blue-500 text-white px-10 py-3 rounded hover:bg-blue-600"
            >
              Teklif Oluştur
            </button>
          </div>
        </div>
      </div>

      <div className=" h-full py-5 sm:max-w-sm md:max-w-md lg:max-w-lg bg-white border border-gray-200 rounded-lg shadow">
        <span className="text-center px-4 text-2xl font-extralight text-blue-500">
          Kalite Kontrol
        </span>
        <div>
          <img
            className="rounded-t-lg mx-auto"
            src={YapayZeka}
            alt=""
            width={120}
          />
        </div>
        <div className="p-5 text-center">
          <p className="mb-3 font-normal text-gray-400">
            10 mm - 30 metre arasında her tür parça, kalıp, fikstür vb.ekipman
            için ölçüm hizmeti alın
          </p>
          <div className="flex justify-center pt-5">
            <button
              onClick={() => {
                user == null
                  ? navigate("/giris")
                  : navigate("/kalitekontrol", {
                      state: { topage: "/kalitekontrol" },
                    });
              }}
              type="submit"
              className=" bg-blue-500 text-white px-10 py-3 rounded hover:bg-blue-600"
            >
              Teklif Oluştur
            </button>
          </div>
        </div>
      </div>

      <div
        id="deneme"
        className="h-full py-5 sm:max-w-sm md:max-w-md lg:max-w-lg bg-white border rounded-lg shadow border-gray-700"
      >
        <span className="text-center px-4 text-2xl font-extralight text-blue-500">
          Tarama Modelleme
        </span>
        <div>
          <img
            className="rounded-t-lg mx-auto"
            src={Tarama}
            alt=""
            width={120}
          />
        </div>
        <div className="p-5 text-center">
          <p className="mb-3 font-normal text-gray-400">
            İster üretim, isterseniz analiz veya karşılaştırma amaçlı Tersine
            Mühendislik hizmeti alabilirsiniz
          </p>
          <div className="flex justify-center pt-5">
            <button
              onClick={() =>
                user == null
                  ? navigate("/giris")
                  : navigate("/taramamodelleme", {
                      state: { topage: "/taramamodelleme" },
                    })
              }
              type="submit"
              className=" bg-blue-500 text-white px-10 py-3 rounded hover:bg-blue-600"
            >
              Teklif Oluştur
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyCards;
