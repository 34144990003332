// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDBUrVic3OQ5rXux6JWYo5OKXxbLyeqdTc",
    authDomain: "robohive-9c678.firebaseapp.com",
    projectId: "robohive-9c678",
    storageBucket: "robohive-9c678.appspot.com",
    messagingSenderId: "568195290486",
    appId: "1:568195290486:web:70d433fc90c6ae71299657",
    measurementId: "G-P8Y8S60CPG"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp)
const fireDB = getFirestore();
const analytics = getAnalytics(firebaseApp);
const strogeDb = getStorage(firebaseApp);

export { firebaseApp, auth, fireDB, strogeDb }