import React from "react";
import Background from "../../Assets/homebackground.jpg";
function IntroText() {
  const backgroundStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <>
      <div
        className="h-full w-full absolute top-0 left-0 z-10"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="h-full w-full backdrop-blur-sm absolute top-0 left-0"></div>
      </div>
      <div className="mx-10 relative z-20">
        <h1 className=" text-blue-500 text-3xl md:text-5xl font-light">
          Talebe Özel Üretim Platformu
        </h1>
        <h1 className="mt-16 max-w-lg text-md md:text-2xl font-thin text-white">
          15 yıl tecrübe, Savunma sanayii, havacılık ve otomotiv Başta olmak
          üzere 2.000 den fazla proje deneyimi. Hizmet, Satış / Entegrasyon ve
          Danışmanlık çözümlerimizi keşfedin
        </h1>
      </div>
      <div
        className="h-[50vh] w-full absolute top-0 left-0"
        style={backgroundStyle}
      />
    </>
  );
}

export default IntroText;
