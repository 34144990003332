import React, { useState } from "react";
import Logo from "../../Assets/weblogo.svg";
import { getAuth } from "firebase/auth";
import { fireDB, firebaseApp, strogeDb } from "../../Config/FirebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  AiOutlineCloudUpload,
  AiOutlineHome,
  AiFillFileAdd,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FcAddImage } from "react-icons/fc";
import LoadingScreen from "../LoadingScreen";

const auth = getAuth(firebaseApp);
function KaliteKontrol() {
  const [user, loading, error] = useAuthState(auth);
  let [adet, setAdet] = useState(1);

  let navigate = useNavigate();

  let [resim1, setResim1] = useState(null);
  let [resim2, setResim2] = useState(null);
  let [resim3, setResim3] = useState(null);
  let [resim4, setResim4] = useState(null);

  let [teknikRef, setTeknikRef] = useState(null);
  let [cadDataRef, setCadDataRef] = useState(null);

  let [eni, setEni] = useState("0");

  let [boyu, setBoyu] = useState("0");

  let [yukseklik, setYukselik] = useState("0");

  let [uploadLoading, setUploadLoading] = useState(null);

  const resimOneHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim1(selectedFile);
  };

  const resimTwoHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim2(selectedFile);
  };

  const resimThreeHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim3(selectedFile);
  };

  const resimForHandle = (event) => {
    const selectedFile = event.target.files[0];
    setResim4(selectedFile);
  };

  const teknikHandle = async (e) => {
    let file = e.target.files[0];
    setTeknikRef(file);
  };

  const cadDataHandle = async (e) => {
    let file = e.target.files[0];
    setCadDataRef(file);
  };
  const handleChange = (event) => {
    const inputQuantity = parseInt(event.target.value);
    if (!isNaN(inputQuantity)) {
      setAdet(Math.max(1, inputQuantity));
    }
  };

  const KaliteKontrolEkle = async () => {
    try {
      let teknikResimLink = null;
      let cadDataLink = null;
      if (user != null) {
        if (
          resim1 != null &&
          resim2 != null &&
          resim3 != null &&
          resim4 != null
        ) {
          setUploadLoading(true);
          let userId = user.uid;
          const yeniMesaj = doc(fireDB, "USERS", userId);
          const itemDocRef = collection(
            doc(collection(fireDB, "USERS"), userId),
            "kaliteKontrol"
          );

          const resim1Ref = ref(
            strogeDb,
            `${userId}/kaliteKontrol/${uuidv4()}`
          );

          await uploadBytes(resim1Ref, resim1);

          let resim1Link = await getDownloadURL(resim1Ref);

          const resim2Ref = ref(
            strogeDb,
            `${userId}/kaliteKontrol/${uuidv4()}`
          );

          await uploadBytes(resim2Ref, resim2);

          let resim2Link = await getDownloadURL(resim2Ref);

          const resim3Ref = ref(
            strogeDb,
            `${userId}/kaliteKontrol/${uuidv4()}`
          );

          await uploadBytes(resim3Ref, resim3);

          let resim3Link = await getDownloadURL(resim3Ref);

          const resim4Ref = ref(
            strogeDb,
            `${userId}/kaliteKontrol/${uuidv4()}`
          );

          await uploadBytes(resim4Ref, resim4);

          let resim4Link = await getDownloadURL(resim4Ref);

          if (teknikRef != null) {
            const teknikResimRef = ref(
              strogeDb,
              `${userId}/kaliteKontrol/${uuidv4()}`
            );

            await uploadBytes(teknikResimRef, teknikRef);

            teknikResimLink = await getDownloadURL(teknikResimRef);
          }

          if (cadDataRef != null) {
            const cadDataReff = ref(
              strogeDb,
              `${userId}/kaliteKontrol/${uuidv4()}`
            );

            await uploadBytes(cadDataReff, cadDataRef);

            cadDataLink = await getDownloadURL(cadDataReff);
          }

          await updateDoc(yeniMesaj, { mesaj: true });

          await addDoc(itemDocRef, {
            boyutBilgisi: `${eni} x ${boyu} x ${yukseklik} mm`,
            dosyaLink: [resim1Link, resim2Link, resim3Link, resim4Link],
            teknikResim: teknikResimLink != null ? teknikResimLink : "bos",
            cadData: cadDataLink != null ? cadDataLink : "bos",
            cadDataIsim: cadDataRef != null ? cadDataRef.name : "bos",
            teknikResimIsim: teknikRef != null ? teknikRef.name : "bos",
            olculecekParcaSayisi: adet,
            incelenmeDurumu: false,
            zaman: Date.now(),
          });
          NotificationManager.success(
            "En kısa sürede dönüş yapılacaktır.",
            "Ürün Eklendi",
            2000
          );

          setTimeout(() => {
            setUploadLoading(false);
            navigate("/");
          }, 3000);
          setResim1(null);
          setResim2(null);
          setResim3(null);
          setResim4(null);

          setEni(0);
          setBoyu(0);
          setYukselik(0);
        } else {
          NotificationManager.info(
            "Lütfen 4 adet resim ekleyniz.",
            "Eksik resim",
            2000
          );
        }
      }
    } catch (e) {
      console.error("Hata oluştu:", e);

      NotificationManager.error("Lütfen Tekrar Deneyiniz", "Hata Oluştu", 2000);
      setUploadLoading(false);
    }
  };

  if (loading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-2/3 lg:w-1/2 xl:w-1/3">
        <div className="flex items-center justify-start mb-6">
          <img src={Logo} alt="Logo" className="w-8 h-8 mr-2" />
          <span className="text-xl font-semibold">Calybre3D</span>
        </div>
        <h2 className="text-2xl font-semibold mb-4">Kalite Kontrol</h2>
        <p className="font-thin">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>

        <label
          htmlFor="name"
          className="block font-medium text-gray-700 mb-2 mt-8"
        >
          Ölçülecek Parça Adet Sayısı:
        </label>
        <div className="flex items-center">
          <input
            value={adet}
            onChange={handleChange}
            type="number"
            pattern="[0-9]*"
            className=" w-20 text-sm py-1 text-center border border-blue-500"
            min="1"
          />
        </div>
        <div className="mb-4 mt-5">
          <label
            htmlFor="file"
            className="block font-medium text-gray-700 mb-2"
          >
            Teknik Resim:{" "}
            <span className="text-opacity-40 text-sm text-black">
              (jpeg, pdf, dwg, dxf)
            </span>
          </label>
          <div className="flex items-center">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="flex flex-col items-center justify-center pt-7">
                {teknikRef === null ? (
                  <>
                    <AiOutlineCloudUpload className="w-12 h-12"></AiOutlineCloudUpload>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Dosya seç
                    </p>
                  </>
                ) : (
                  <>
                    <AiFillFileAdd className="w-12 h-12"></AiFillFileAdd>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {teknikRef.name}
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                className="opacity-0"
                onChange={teknikHandle}
              />
            </label>
          </div>
        </div>

        <div className="mb-4 mt-5">
          <label
            htmlFor="file"
            className="block font-medium text-gray-700 mb-2"
          >
            Cad Data:{" "}
            <span className="text-opacity-40 text-sm text-black">
              (stl, step)
            </span>
          </label>
          <div className="flex items-center">
            <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="flex flex-col items-center justify-center pt-7">
                {cadDataRef === null ? (
                  <>
                    <AiOutlineCloudUpload className="w-12 h-12"></AiOutlineCloudUpload>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      Dosya seç
                    </p>
                  </>
                ) : (
                  <>
                    <AiFillFileAdd className="w-12 h-12"></AiFillFileAdd>{" "}
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {cadDataRef.name}
                    </p>
                  </>
                )}
              </div>
              <input
                type="file"
                className="opacity-0"
                onChange={cadDataHandle}
              />
            </label>
          </div>
        </div>

        <div className="mb-4 ">
          <label
            htmlFor="email"
            className="block font-medium text-gray-700 mb-2"
          >
            Ürün Resmi:
          </label>
          <div className="flex justify-evenly mt-10">
            <div
              className={`${
                resim1 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim1 ? (
                <img
                  src={URL.createObjectURL(resim1)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="1" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="1"
                accept="image/*"
                className="hidden"
                onChange={resimOneHandle}
              />
            </div>

            <div
              className={`${
                resim2 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim2 ? (
                <img
                  src={URL.createObjectURL(resim2)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="2" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="2"
                accept="image/*"
                className="hidden"
                onChange={resimTwoHandle}
              />
            </div>

            <div
              className={`${
                resim3 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim3 ? (
                <img
                  src={URL.createObjectURL(resim3)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="3" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="3"
                accept="image/*"
                className="hidden"
                onChange={resimThreeHandle}
              />
            </div>

            <div
              className={`${
                resim4 ? "w-32 h-32" : "bg-gray-300 p-5"
              } rounded-md`}
            >
              {resim4 ? (
                <img
                  src={URL.createObjectURL(resim4)}
                  alt="Selected"
                  className="flex h-32 w-32"
                />
              ) : (
                <label htmlFor="4" className="cursor-pointer">
                  <FcAddImage className="h-16 w-16" />
                </label>
              )}
              <input
                type="file"
                id="4"
                accept="image/*"
                className="hidden"
                onChange={resimForHandle}
              />
            </div>
          </div>
        </div>

        {uploadLoading ? (
          <div className="flex mx-auto w-full mt-10 items-center justify-center ">
            <div
              className="  top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
              style={{
                borderTop: "2px solid transparent",
                borderBottom: "2px solid transparent",
              }}
            ></div>
          </div>
        ) : (
          <div className=" mt-10 mb-5 flex flex-row justify-center">
            <button
              onClick={() => KaliteKontrolEkle()}
              className="px-12 py-2  bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
            >
              Gönder
            </button>
          </div>
        )}
      </div>

      <button
        className="fixed text-3xl bottom-4 right-4 w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={() => {
          // FAB'a tıklandığında yapılacak işlemi buraya ekleyebilirsiniz
          navigate("/");
        }}
      >
        <AiOutlineHome></AiOutlineHome>
      </button>
      <NotificationContainer />
    </div>
  );
}

export default KaliteKontrol;
