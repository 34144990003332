import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import HomePage from "./Component/HomePage";
import LoginPage from "./Component/Auth/LoginPage";
import SignUp from "./Component/Auth/SignUp";

import Prototipleme from "./Component/Prototipleme/Prototipleme";

import { doc, getDoc, getFirestore } from "firebase/firestore";

import { firebaseApp } from "./Config/FirebaseConfig";
import { getAuth, signOut } from "firebase/auth";

import TaramaModelleme from "./Component/TaramaModelleme/TaramaModelleme";
import KaliteKontrol from "./Component/KaliteKontrol/KaliteKontrol";
const auth = getAuth(firebaseApp);
let fireDb = getFirestore(firebaseApp);
function App() {
  useEffect(() => {
    return async () => {
      await userAuthentication();
    };
  });

  const userAuthentication = async () => {
    if (auth.currentUser !== null) {
      const documentRef = doc(fireDb, "USERS", auth.currentUser.uid);

      let gelenDoc = await getDoc(documentRef);
      if (!gelenDoc.exists()) {
        logout();
      }
    }
  };

  const logout = () => {
    signOut(auth);
  };

  //<Navigate to="/giris" ></Navigate>

  return (
    <Routes>
      <Route path="/" element={<HomePage></HomePage>}></Route>
      <Route path="/giris" element={<LoginPage></LoginPage>}></Route>
      <Route path="/kayit" element={<SignUp></SignUp>}></Route>
      <Route
        path="/prototipleme"
        element={<Prototipleme></Prototipleme>}
      ></Route>
      <Route
        path="/taramamodelleme"
        element={<TaramaModelleme></TaramaModelleme>}
      ></Route>
      <Route
        path="/kalitekontrol"
        element={<KaliteKontrol></KaliteKontrol>}
      ></Route>
    </Routes>
  );
}

export default App;
