import React from "react";

function LoadingScreen() {
  return (
    <div className="flex min-h-screen bg-gray-800 justify-center items-center ">
      <div className="relative w-10 h-10 mx-auto py-4">
        <div
          className="absolute top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
          style={{
            borderTop: "2px solid transparent",
            borderBottom: "2px solid transparent",
          }}
        ></div>
      </div>
    </div>
  );
}

export default LoadingScreen;
