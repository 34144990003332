import React from "react";
import Kalite from "../../Assets/kalite.svg";
import Fiyat from "../../Assets/anlikfiyat.svg";
import Network from "../../Assets/network.svg";
function ReferanslarAciklama() {
  return (
    <div>
      <div className="mb-14">
        <h1 className="text-4xl text-center pt-20 text-white font-extralight">
          Biz Kimiz?
        </h1>
        <p className="text-xl max-w-md text-center mx-auto pt-5 font-extralight text-gray-200">
          15 yıl tecrübe, Savunma sanayii, havacılık ve otomotiv Başta olmak
          üzere 2.000 den fazla proje deneyimi. Hizmet, Satış / Entegrasyon ve
          Danışmanlık çözümlerimizi keşfedin
        </p>
      </div>
      <div className="grid lg:grid-cols-3 gap-5 lg:gap-5 md:justify-center md:items-center">
        <div className=" h-full text-center  py-10  px-10 text-sm ">
          <img alt="logo" className="mx-auto w-28 h-28" src={Network}></img>
          <h1 className="mx-auto text-gray-200 max-w-sm py-5 font-extralight">
            15 yıldır sağladığımız hizmetleri online platforma taşıdık. Calybre
            3D hızlı tekliflendirme, hizmet takibi ve online ödeme sistemi ile
            mühendislik hizmetlerini güvenli, hızlı, doğru ve uygun maliyetli
            size ulaştırıyoruz
          </h1>
        </div>

        <div className=" h-full gap-5 text-center px-10 py-10 text-sm">
          <img alt="logo" className="mx-auto w-28 h-28" src={Fiyat}></img>
          <h1 className="mx-auto text-gray-200 max-w-sm py-5 font-extralight">
            Calybre 3D hızlı ve doğru tekliflendirme için yapay zeka ve akıllı
            algoritmalar kullanan, sürekli geliştirilen bir altyapıya sahiptir
          </h1>
        </div>

        <div className=" h-full text-center px-10  py-10 text-sm">
          <img alt="logo" className="mx-auto w-28 h-28 " src={Kalite}></img>
          <h1 className=" mx-auto text-gray-200 max-w-sm py-5 font-extralight">
            Gizliliğiniz ve verileriniz korumamız altındadır.
          </h1>
        </div>
      </div>
    </div>
  );
}

export default ReferanslarAciklama;
