import React, { useState } from "react";
import Logo from "../Assets/weblogo.svg";
import { useNavigate } from "react-router-dom/dist";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp } from "../Config/FirebaseConfig";
import { IoPerson } from "react-icons/io5";
import { BiExit } from "react-icons/bi";

const auth = getAuth(firebaseApp);

function AppBar() {
  let navigate = useNavigate();
  const [profileCard, setProfileCard] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [mobileAppBar, setMobileAppBar] = useState(false);

  const [user, loading, error] = useAuthState(auth);

  const toggleDropdown = () => {
    setProfileCard(!profileCard);
  };

  const toggleMobileMenu = () => {
    setMobileAppBar(!mobileAppBar);
  };

  const logOut = async () => {
    signOut(auth);
  };

  if (error) {
    return <div>Hata</div>;
  }
  return (
    <nav className=" border-gray-200 bg-gray-900 ">
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
        <button className="flex items-center">
          <img src={Logo} className="h-8 mr-3" alt="Flowbite Logo" />
          <span className="self-stretch text-2xl font-semibold whitespace-nowrap text-white">
            Calybre3D
          </span>
        </button>
        <button
          onClick={() => {
            toggleMobileMenu();
          }}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden  focus:outline-none focus:ring-2  text-gray-400 hover:bg-gray-700 focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Menü</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div
          className={`${
            mobileAppBar ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border md:flex-row md:space-x-8 md:mt-0 md:border-0 bg-gray-900 border-gray-700">
            {loading === true ? (
              <div className="relative w-5 h-5">
                <div
                  className="absolute top-0 left-0 w-10 h-10 border rounded-full border-blue-500 animate-spin"
                  style={{
                    borderTop: "2px solid transparent",
                    borderBottom: "2px solid transparent",
                  }}
                ></div>
              </div>
            ) : user === null ? (
              <li
                onClick={() => navigate("/giris")}
                className=" bg-blue-500 hover:bg-blue-800 px-5 py-3 rounded-lg"
              >
                <button
                  onClick={() => navigate("/giris")}
                  className="block px-2 py-2 pl-3 pr-4 rounded  md:border-0 md:hover:text-white-700 md:p-0 text-white md:hover:text-white-500 hover:bg-gray-700 hover:text-white md:hover:bg-transparent"
                >
                  Giriş Yap
                </button>
              </li>
            ) : (
              <li className=" bg-blue-900 hover:bg-blue-800 px-2 py-2 rounded-3xl">
                <button
                  onClick={async () => {
                    if (mobileAppBar) {
                      await logOut();
                    } else {
                      toggleDropdown();
                    }
                  }}
                  className="block px-2 py-2 pl-3 pr-4 rounded  md:border-0 md:hover:text-white-700 md:p-0 text-white md:hover:text-white-500 
                                 hover:text-white md:hover:bg-transparent"
                >
                  <div className="flex justify-between">
                    <IoPerson size={30}></IoPerson>
                    <h1 className="text-xl mx-5 md:text-xs md:mt-2 md:mx-2">
                      {user.email}
                    </h1>{" "}
                    <BiExit
                      className={`${
                        mobileAppBar ? "text-3xl" : "hidden"
                      } md:hidden`}
                    ></BiExit>
                  </div>
                </button>
              </li>
            )}
          </ul>
        </div>
        {profileCard && (
          <div className="absolute right-0 mt-36 mr-12 w-44 py-2 bg-white rounded-lg shadow-xl z-40">
            <button className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
              Ayarlar
            </button>
            <button
              onClick={async () => {
                toggleDropdown();
                await logOut();
              }}
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
            >
              Çıkış yap
            </button>
          </div>
        )}
      </div>

      {openAlert && <LogoutAlert></LogoutAlert>}
    </nav>
  );
  function LogoutAlert() {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center">
        <div className="bg-white bg-opacity-50 p-2 rounded-lg h-48 w-96 justify-around flex flex-col">
          <div className="flex w-full text-6xl items-center justify-center">
            <BiExit></BiExit>
          </div>

          <div className="flex flex-col">
            <span className="text-center">
              Hesaptan çıkış mı yapmak istiyorsunuz ?{" "}
            </span>
          </div>
          <div className="w-full justify-around flex">
            <button className="px-5 py-2 bg-blue-500 text-white rounded">
              Çıkış Yap
            </button>
            <button
              onClick={() => {
                setOpenAlert(false);
              }}
              className="px-9 py-2 bg-red-500 text-white rounded"
            >
              İptal
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AppBar;
